import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import styled from "styled-components";
import {graphql, Link, useStaticQuery} from "gatsby"
import {Container} from "../styles/global";
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const Blog = ({location}) => {
    const data = useStaticQuery(
        graphql`
      query {
        allContentfulBlogPost(sort: {fields: publishDate, order: DESC}) {
            edges {
              node {
                id
                slug
                author {
                  name
                }
                title
                publishDate(formatString: "Do MMMM, YYYY")
                description {
                  description
                }
                heroImage {
                  publicUrl
                  title
                  
                  
                  gatsbyImageData(
                    width: 750
                    placeholder: BLURRED
                  )
                 
                }
              }
            }
        }
      }
    `
    )
    const metadata = {
        title: "Jinolo Blog",
        description: "Jinolo has been featured in the Australian Financial Review, Inside Small Business and many more. Sign up free."

    }
    return (
        <Layout location={location}>
            <SEO
                title="Blog"
                description="Blog covering topics related to 3D, engineering and design. "

            />

            <div>
                <Container>
                    {/*<SEO title="Blog"/>*/}
                    <BlogGroup>
                        <Title className="mb-3">Jinolo Blog</Title>
                        <p className="mb-7 mt-3 font-grotesk-medium text-center">Blog covering topics related to 3D, engineering and design.</p>
                        <main className=" grid lg:grid-cols-2 md:grid-cols-1 gap-4">
                            {data.allContentfulBlogPost.edges.map(edge => {
                                // {edges.map(({node}, i) => (
                                return (
                                    <div key={edge.node.id} className="py-2 hover:shadow-md ">


                                        <div className="">
                                            <Link to={`/blog/${edge.node.slug}/`} className="text-white">

                                                {edge.node.heroImage && (
                                                    <GatsbyImage image={getImage(edge.node.heroImage)}
                                                                 alt={edge.node.heroImage.title}/>

                                                )}
                                            </Link>
                                            <div className="px-7 py-2">
                                                <Link to={`/blog/${edge.node.slug}/`}
                                                      className="text-gray-800 hover:text-gray-500"
                                                      style={{textDecoration: 'none', color: 'inherit'}}>
                                                    <h2 className="text-gray-800 mb-0.5">{edge.node.title} </h2>
                                                    <small className="text-gray-800">{edge.node.publishDate}</small>

                                                    <p className="pt-2">
                                                        {edge.node.description.description}
                                                    </p>


                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })}
                        </main>
                    </BlogGroup>
                </Container>
            </div>

        </Layout>
    )
}
export default Blog

const BlogGroup = styled.div`

  padding: 160px 20px 10px 60px;
  position: relative;
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  // margin: 0;
  // padding: 20px;

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.navy};
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.navy};
  }

  p {
    color: ${props => props.theme.color.navy};
    margin-bottom: 24px;
  }
`
const Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: #121640;
  margin-bottom: 44px;
  text-align: center;
  margin-top: 0;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    font-size: 32px;
  }
`
